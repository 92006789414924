import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../SEO';
import Navbar from '../components/navbar';
import { Footer } from '../components/footer';

const NotFoundPage = () => (
  <return>
    <SEO />
    <Navbar />
    <div className="flex flex-1 flex-col items-center my-24">
      <StaticImage
        src="../images/notfound.svg"
        alt="Not found"
      />
      <h1 className="text-5xl font-bold text-center text-primary" style={{ fontSize: 72 }}>404</h1>
      <div className="text-center p-5 md:w-2/5">
        <p className="text-3xl font-medium text-primary">Halaman tidak ditemukan.</p>
        <p className="mt-5">Jika Anda sudah memasukkan link yang sesuai,
          maka Anda dapat menghubungi Customer Happiness kami untuk mengajukan pertanyaan atau Kembali ke Beranda
        </p>
      </div>
    </div>
    <Footer />
  </return>
);

export default NotFoundPage;
